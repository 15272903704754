import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import "./style.scss"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout previous={frontmatter.previous} next={frontmatter.next}>

    <div className="padding_10 font_lucida">
      <nav class="breadcrumb is-right" aria-label="breadcrumbs">
        <ul>
          <li><a href="/">Accueil</a></li>
          <li className="is-active"><a href="#">{frontmatter.title}</a></li>
        </ul>
      </nav>

    <div style={{marginBottom: "2vw", fontSize: "1.5em"}}>
      <a href="/alacarte">👈  Retour à la carte</a>
    </div>
    
	  <h1 className="title is-1"><span style={{backgroundColor: frontmatter.htColor}}>{frontmatter.title}</span> <img id="titleLogo" src={frontmatter.logoTitleUrl} style={{width: "150px"}}/></h1>
    
	  <p className="title is-4">{frontmatter.keywords} <img id="subtitleLogo" src={frontmatter.logoUrl} /> <img id="subtitleLogo2" src={frontmatter.logoUrl2} /> <img id="subtitleLogo3" src={frontmatter.logoUrl3} /> <img id="subtitleLogo4" src={frontmatter.logoUrl4} /></p>

        <div
          dangerouslySetInnerHTML={{ __html: html }}
        />
    </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        keywords
        htColor
        logoTitleUrl
        logoUrl
        logoUrl2
        logoUrl3
        logoUrl4
	      previous
	      next
      }
    }
  }
`
